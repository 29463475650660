/* eslint-disable */

// disable patching requestAnimationFrame
(window as any).__Zone_disable_requestAnimationFrame = true;

// disable patching specified eventNames
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove'];

// Make using stencil's webcomponent 600% faster. (Opt out of double change detection)
// Sauce: https://medium.com/8451/600-faster-stencil-components-in-angular-d442fb87babd
(window as any).__Zone_disable_customElements = true;
